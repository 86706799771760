import React from "react"
import Chip from "./chip"
import ModalComponent from "./modal"

const CategoriesPannel = (props) => {

    const urlPrefix = props.chosenCategory ? '../' : './';

    return (
        <div className="categoriesPannel">
            <ModalComponent showModal={props.showModal} onClose={props.onClose} title="Tags">
                <div className="columns is-multiline categories-list 
                            is-mobile is-variable is-1">
                    {props.defaultCategoryName &&
                        <Chip color="white" name={props.defaultCategoryName} isActive={!props.chosenCategory}
                            Link={urlPrefix} color="light" onClick={props.filterByCategory} />}

                    {props.categories.map(category => (
                        <Chip key={category.slug} name={category.name} isActive={category.slug === props.chosenCategory}
                            Link={`${urlPrefix}${category.slug}`} color="light" slug={category.slug}
                            onClick={props.filterByCategory} />
                    ))}
                </div>
            </ModalComponent>
        </div>
    )
}

export default CategoriesPannel
