import React from "react"

const ModalComponent = (props) => {
    return (
        <div className={`modal ${props.showModal ? 'is-active' : ''}`}>
            <div className="modal-background" onClick={props.onClose}></div>
            <div className="modal-card">
                <section className="modal-card-body box">
                    <div className="mb-4">
                        <button className="delete" aria-label="close" onClick={props.onClose}></button>
                        {props.title && <div className="title is-4">{props.title}</div>}
                    </div>
                    <div className="block">
                        {props.children}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ModalComponent