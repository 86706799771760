import { Link } from "gatsby"
import React from "react"

const Chip = (props) => {

    const className = `button p-2
        ${props.isLarge ? "is-large" : "is-medium"} 
        ${props.isActive ? "is-primary is-outlined" : ""}
        ${!props.isActive && props.color ? `is-${props.color}` : ""}`;

    return (
        <div className="column is-narrow">
            {!props.onClick && <Link to={props.Link}>
                <button className={className}>
                    {props.name}
                </button>
            </Link>}
            {props.onClick &&
                <button className={className} onClick={() => props.onClick(props.slug)}>
                    {props.name}
                </button>}
        </div>
    )
}

export default Chip
